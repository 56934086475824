<template>
  <div class="yt-come-on-season">
    <div class="bg">
      <img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-13/15/yuelvhuiPDRa6JADcw1631519706.png" alt="">
    </div>
    <div class="content-info">
      <div class="title">
        <img src="./assets/image/title-active-2.9.png" alt="">
      </div>
      <div class="coupons" @click="handleOpenSeason">
        <img src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-09-13/16/yuelvhui8tYrTf5o6B1631521981.png" alt="">
      </div>
      <div class="title">
        <img src="./assets/image/title-active-50.png" alt="">
      </div>
      <div class="coupons" @click="handleCoupons">
        <img src="./assets/image/coupons-50.png" alt="">
      </div>
      <!--<div class="title">-->
      <!--  <img src="./assets/image/title-active-line.png" alt="">-->
      <!--</div>-->
      <!--<div class="line" @click="handleLineDetail">-->
      <!--  <img src="./assets/image/line-bg.png" alt="">-->
      <!--</div>-->
      <div class="share-button" @click="handleDownSharePicture">
        <img src="./assets/image/share-button.png" alt="">
      </div>
    </div>

    <div class="rules-active">
      <div class="title">优惠卷使用规则：</div>
      <div class="content">
        <p>1、活动时间：2021年9月10日-12月31日</p>
        <p>2、优惠券领取后立即生效，7日内有效</p>
        <p>3、加油券仅可在悦淘APP内使用，适用于滴滴加油合作油站</p>
        <p>4、优惠券每次可使用1张，不与直降等其他优惠叠加使用</p>
        <p>最终解释权归悦淘所有</p>
      </div>
    </div>
    <van-popup v-model="popupVisible" class="popup-rules" :lock-scroll="true" :close-on-click-overlay="true">
      <div class="popup-rules-content" @click.stop>

        <!-- 活动规则内容-->
        <div class="rules-content">
          <img :src="shareImg" alt="" @click="handleDownloadPicture">
        </div>

        <!-- 活动规则内容-->
        <div class="btn-content">
          点击或长按保存图片，分享给好友
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { wxConfigInit } from '@/utils/wx-api'
import { changeURLArg } from '@/utils/common/utils'
import { isYTApp } from '@/utils/common/validate'
import Vue from 'vue'
import { Popup } from 'vant'
import { routerRN } from '@/utils/app'
import { getDdComeOnShare } from '@/services/activity/come-on-season'
import store from '@/stores'
import { getusernew } from '@/services/userApi'

Vue.use(Popup)
export default {
  name: 'YtComeOnSeason',
  data() {
    return {
      popupVisible: false,
      reCode: null,
      codeNumber: null,
      shareImg: ''
    }
  },
  created() {
    const {
      codeNumber,
      reCode = localStorage.getItem('recode'),
      mid = localStorage.getItem('uid'),
      token = localStorage.getItem('uid')
    } = this.$route.query
    this.reCode = reCode
    this.codeNumber = codeNumber

    if (!isYTApp()) {
      this.handleWxShareInfo()
    } else {
      window.RNshareDataChange = this.handleWxShareInfo
      localStorage.setItem('recode', reCode)
      if (mid && token) {
        localStorage.setItem('uid', mid)
        localStorage.setItem('token', token)
      }
    }
  },
  methods: {

    /**
     * 处理打开App登录
     * 2021-05-28 16:33:19
     * @author SnowRock
     */
    handleOpenAppLogin() {
      this.$store.dispatch('RNrouter', { eventName: 'login' })
    },
    /**
     * 处理微信配置分享
     * 2021-07-22 19:35:40
     * @author SnowRock
     */
    handleWxShareInfo() {
      const codeNumber = this.reCode
      const url = window.location.href
      const shareInfo = {
        title: '悦淘加油季', // 分享标题
        desc: '【悦淘加油季】加油直降！最高直降2.9元/升！嗨抢50元红包！', // 分享描述
        imgUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2021-07-25/15/yuelvhuiYqr05vqhKi1627197991.png', // 分享图标
        link: changeURLArg(url, 'codeNumber', codeNumber)
      }
      if (isYTApp()) {
        const appShare = {
          eventName: 'share',
          image: shareInfo.imgUrl, // 分享标题
          title: shareInfo.title,
          des: shareInfo.desc,
          url: shareInfo.link
        }
        this.$store.dispatch('RNrouter', appShare)
      } else {
        wxConfigInit(shareInfo)
      }
    },
    /**
     * 处理登录问题及配置
     * 2021-07-25 16:05:03
     * @author SnowRock
     */
    handleLogin() {
      const mid = localStorage.getItem('uid')
      const token = localStorage.getItem('token')
      if (!(mid && token)) {
        if (isYTApp()) {
          store.dispatch('RNrouter', { eventName: 'login' })
        } else {
          this.$router.replace('/login?backUrl=' + encodeURIComponent(encodeURIComponent(window.location.href)))
        }
        return true
      }
      return false
    },
    /**
     * 跳转优惠券领取页面
     * 2021-07-22 19:35:40
     * @author SnowRock
     */
    handleCoupons() {
      if (this.handleLogin()) {
        return
      }
      getusernew().then(res => {
        const { code, data } = res
        if (code === 200) {
          const { mobile } = data
          this.$router.push({
            path: '/oilConponIndex',
            query: {
              codeNumber: this.reCode,
              mobile: mobile,
              mid: localStorage.getItem('uid')
            }
          })
        }
      })
    },
    /**
     * 处理跳转加油
     * 2021-07-22 19:35:40
     * @author SnowRock
     */
    handleOpenSeason() {
      if (this.handleLogin()) {
        return
      }
      if (isYTApp()) {
        // 跳转加油站列表的页面
        routerRN({
          redirectType: 61
        })
      } else {
        this.$router.push({
          path: '/comeOnDataList'
        })
      }
    },
    /**
     * 处理分享海波图片地址
     */
    async getSharePicture() {
      if (this.handleLogin()) {
        return
      }
      const res = await getDdComeOnShare({ codeNumber: this.reCode })
      const { code, data } = res
      if (Number(code) === 200) {
        this.shareImg = data.shareImg
      }
    },
    /**
     * 处理下载海报分享页面
     * 2021-07-22 20:11:07
     * @author SnowRock
     */
    async handleDownSharePicture() {
      // 处理分享图片
      await this.getSharePicture()
      this.popupVisible = true
    },
    /**
     * 下载图片
     * 2021-07-22 23:09:03
     * @author SnowRock
     */
    handleDownloadPicture() {
      if (isYTApp()) {
        // 打开App分享功能
        this.$store.dispatch('RNrouter', {
          eventName: 'oilShare',
          oilShareImage: this.shareImg
        })
        return
      }
    },
    /**
     * 处理线路信息
     * 2021-07-22 19:50:09
     * @author SnowRock
     */
    handleLineDetail() {
      if (this.handleLogin()) {
        return
      }
      const obj = {
        goodId: '8297347',
        skuId: '71839244',
        product_type: 1
      }
      this.$store.dispatch('godetail', obj)
    }
  }
}
</script>

<style lang="less" scoped>
.yt-come-on-season {
  min-height: 100vh;
  background: #BF95EC;
  padding-bottom: 40px;
  .bg {
    img {
      width: 100%;
      height: auto;
    }
  }
  .content-info {
    width: calc(100% - 20px);
    box-sizing: border-box;
    padding: 20px 10px 10px;
    background: #ffffff;
    border-radius: 6px;
    margin: 0 auto;

    .title {
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 0 auto 10px;
      img {
        height: 20px;
      }
    }
    .coupons {
      margin-bottom: 23px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .line {
      margin-bottom: 25px;
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .rules-active {
    width: calc(100% - 32px);
    box-sizing: border-box;
    color: #000;
    line-height: 20px;
    text-align: left;
    margin: 28px auto 0;
    font-weight: bold;
    font-family: PingFang SC, PingFang SC-Regular,sans-serif;
    .title {
      font-size: 15px;
      line-height: 30px;
    }
    .content {
      font-size: 13px;
    }
  }
}
::v-deep .popup-rules {
  background: transparent;
}
.popup-rules-content {
  width: 223px;
  opacity: 1;
  background: transparent;
  border-radius: 5pt;
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;

  .rules-title {
    font-size: 15px;
    line-height: 16px;
    margin-bottom: 22px;
  }
  .rules-content {
    margin-bottom: 23px;
    text-align: left;
  }
  .btn-content {
    color: #fff;
    margin-bottom: 10px;
    font-weight: bold;
  }
}
</style>
