
/**
 * 仰生活
 * 2021-07-16 17:45:24
 * @author SnowRock
 */
import request from '@/services/request'

/**
 * 获取加油季分享图片
 * 2021-07-16 17:46:39
 * @author SnowRock
 * @param params
 * @param params.codeNumber 推广码
 * @returns {Promise<*|undefined>}
 */
export function getDdComeOnShare(params) {
  return request({
    url: '/youxuan/getDdJiayouShare',
    method: 'post',
    data: params
  })
}
